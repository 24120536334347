import jQuery from 'jquery';

//--------------------------------------------------
// Accordion
jQuery(($) => {
  if ($('.accordion').length) {
    const {hash} = window.location;

    $('.accordion').each(function () {
      const item = $(this);
      const question = $('.item-title', item);
      const answer = $('.item-content', item);
      const id = question.attr('id');
      if (parseInt(id) === parseInt(hash.substring(1))) {
        question.addClass('current').attr('aria-expanded', 'true');
        answer.attr('hidden', false);
      } else {
        question.attr('aria-expanded', 'false');
        answer.attr('hidden', true);
      }

      question.on('click', function (e) {
        e.preventDefault();
        const currentQ = $(this);

        //--------------------------------------------------
        // Hide/Show - allow current FAQ to be hidden too

        if (!currentQ.hasClass('current')) {
          question.addClass('current').attr('aria-expanded', 'true');
          answer.attr('hidden', false);
        } else {
          question.removeClass('current').attr('aria-expanded', 'false');
          answer.attr('hidden', true);
        }
      });
    }); // .accordion each
  } // if .accordion
});
